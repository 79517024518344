/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Employees.unshift(item)
  },
  SET_Employees(state, Employees) {
    debugger;
    try {
    
      state.Employees = Employees;
    } catch (error) {
      console.error("Failed to parse Employees data JSON:", error);
      state.Employees = [];
    }
  },
  SET_Operations(state, Operations) {
    debugger;
    try {
    
      state.Operations = Operations;
    } catch (error) {
      console.error("Failed to parse Operations data JSON:", error);
      state.Operations = [];
    }
  },
  SET_Pages(state, Pages) {
    debugger;
    try {
    
      state.Pages = Pages;
    } catch (error) {
      console.error("Failed to parse Pages data JSON:", error);
      state.Pages = [];
    }
  },
  // UPDATE_Country(state, country) {
  //   debugger
  //   const countryIndex = state.countries.findIndex((p) => p.Id == country.Id)
  //   Object.assign(state.countries[countryIndex], country)
  // },
  // REMOVE_ITEM(state, itemId) {
  //     const ItemIndex = state.countries.findIndex((p) => p.Id == itemId)
  //     state.countries.splice(ItemIndex, 1)
  // },
}
