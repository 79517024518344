/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.post("API/Employee/AddPagesAndOperations", item,{headers:Token})
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { Id: response.data.Id }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchEmployees({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/Employee/Index",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_Employees', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchOperations({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/Operation/Index",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_Operations', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchPages({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/Page/Index",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_Pages', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // fetchDataListItems({ commit }) {
  //   debugger;
  //   var Token={Authorization: 'Bearer ' + localStorage.accessToken};
  //   return new Promise((resolve, reject) => {
  //     axios.get("API/Country/GetCountryList",{headers:Token})
  //       .then((response) => {
  //         debugger;
  //         commit('SET_Countries', response.data.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  // updateItem({ commit }, item) {
  //   var Token={Authorization: 'Bearer ' + localStorage.accessToken};
  //   return new Promise((resolve, reject) => {
  //     axios.put("API/Country/UpdateCountry", item,{headers:Token})
  //       .then((response) => {
  //         commit('UPDATE_Country', item)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  // GetItemByID({ commit }, itemid) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("API/Country/GetCountryByID?Id=" + itemid)
  //       .then((response) => {
  //         commit('UPDATE_Country', response.data.Data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  // removeItem({ commit }, item) {
  //   debugger;
  //   var Token={Authorization: 'Bearer ' + localStorage.accessToken};
  //   return new Promise((resolve, reject) => {
  //     axios.delete("API/Country/DeleteCountry?countryId="+item.Id,{headers:Token})
  //       .then((response) => {
  //         commit('REMOVE_ITEM', item.Id)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
}
