<template>
  <div class="employee-action-container">
    <!-- Left Column: Employee List with Search Filter -->
    <div class="employee-list">
      <h3>{{$t("Employees")}}</h3>
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search employees..."
        class="search-box"
      />
      <ul>
        <li
          v-for="employee in filteredEmployees"
          :key="employee.Id"
          :class="{ selected: selectedEmployee === employee.Id }"
        >
          <input
            type="radio"
            :value="employee.Id"
            v-model="selectedEmployee"
          />
          {{ employee.NameAR }}
        </li>
      </ul>
    </div>

    <!-- Middle Section: Page Selection -->
    <div class="pages-list">
      <h3>{{$t("_Pages")}}</h3>
      <input
        type="text"
        v-model="searchQuery2"
        placeholder="Search pages..."
        class="search-box"
      />
      <ul>
        <li
          v-for="page in filteredPages"
          :key="page.Id"
          :class="{ selected: selectedPage === page.Id }"
        >
          <input
            type="radio"
            :value="page.Id"
            v-model="selectedPage"
          />
        {{$t(page.Name)}}
        </li>
      </ul>
    </div>

    <!-- Right Section: Actions Checklist -->
    <div class="actions-list">
      <h3>{{$t("Operation")}}</h3>
      <table>
        <thead>
          <tr>
            <th>{{$t("Operation")}}</th>
            <th v-if="selectedEmployee">
              {{ selectedEmployeeName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="action in Operations" :key="action.Name">
            <td>{{$t(action.Name)}}</td>
            <td v-if="selectedEmployee">
              <!-- Bind checkbox to a specific employee's operations -->
              <input
                type="checkbox"
                :value="action.Name"
                :checked="isOperationSelected(action.Name)"
                @change="toggleOperation(action.Name)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Submit Button -->
      <button @click="submitOperations" class="submit-button">
        {{$t("Submit")}}
      </button>
    </div>
  </div>
</template>

<script>
  import moduleEmployee from "@/store/Employee/moduleEmployee.js";
  
  export default {
    data() {
      return {
        employees: [],
        Operations: [], // Array of operations
        pages: [], // Array for list of pages
        searchQuery: "",
        searchQuery2: "",
        selectedEmployee: null, // Single selected employee
        selectedPage: null, // Single selected page
        employeeOperations: {}, // Store selected page and operations for each employee
      };
    },
    created() {
      if (!moduleEmployee.isRegistered) {
        this.$store.registerModule("EmployeeList", moduleEmployee);
        moduleEmployee.isRegistered = true;
      }
      
      // Fetch employees
      this.$store.dispatch("EmployeeList/fetchEmployees")
        .then(response => {
          if (Array.isArray(response.data.data)) {
            this.employees = response.data.data;
          } else {
            this.employees = [];
          }
        })
        .catch(error => {
          console.error(error);
          this.employees = [];
        });

      // Fetch operations
      this.$store.dispatch("EmployeeList/fetchOperations")
        .then(response => {
          if (Array.isArray(response.data.data)) {
            this.Operations = response.data.data;
          } else {
            this.Operations = [];
          }
        })
        .catch(error => {
          console.error(error);
          this.Operations = [];
        });

      // Fetch pages
      this.$store.dispatch("EmployeeList/fetchPages")
        .then(response => {
          if (Array.isArray(response.data.data)) {
            this.pages = response.data.data;
          } else {
            this.pages = [];
          }
        })
        .catch(error => {
          console.error(error);
          this.pages = [];
        });
    },
    computed: {
      filteredEmployees() {
        return this.employees.filter(employee =>
          employee.NameAR.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      },
      filteredPages() {
        return this.pages.filter(page =>
          page.Name.toLowerCase().includes(this.searchQuery2.toLowerCase())
        );
      },
      selectedEmployeeName() {
        const employee = this.employees.find(e => e.Id === this.selectedEmployee);
        return employee ? employee.NameAR : '';
      },
    },
    methods: {
      isOperationSelected(operationName) {
        // Check if the operation is selected for the current employee
        return this.employeeOperations[this.selectedEmployee] && this.employeeOperations[this.selectedEmployee].includes(operationName);
      },
      toggleOperation(operationName) {
        // Ensure operations array exists for the selected employee
        if (!this.employeeOperations[this.selectedEmployee]) {
          this.$set(this.employeeOperations, this.selectedEmployee, []);
        }

        const operations = this.employeeOperations[this.selectedEmployee];
        const operationIndex = operations.indexOf(operationName);
        
        // If the operation is already selected, remove it; otherwise, add it
        if (operationIndex !== -1) {
          operations.splice(operationIndex, 1); // Remove operation
        } else {
          operations.push(operationName); // Add operation
        }
      },
      submitOperations() {
        const result = Object.entries(this.employeeOperations).map(([employeeId, operations]) => ({
          PageId: this.selectedPage,
          EmployeeId: employeeId,
          Operations: operations,
        }));
        this.$store.dispatch("EmployeeList/addItem",result).then(response => {
       //   location.reload();
         if(response.data.message=="Data Added Succesfuly"){
          debugger;
          this.selectedEmployee = null;
          this.selectedPage = null;
          this.employeeOperations = {};
            this.$vs.notify({ time:8000,
        color: "success",
       // title: "Deleted record",
        text: response.data.message,
      });
      
         }
        })
        .catch(error => {
          console.error(error);
          this.pages = [];
        });
        //console.log("Submitted Data:", result);
        // Send the result to the server (API call can be added here)
      },
    },
  };
</script>

<style scoped>
.employee-action-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.employee-list, .pages-list, .actions-list {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.employee-list {
  width: 200px;
}

.pages-list {
  width: 200px;
}

.search-box {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.employee-list ul, .pages-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
}

.employee-list li, .pages-list li {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.employee-list li.selected, .pages-list li.selected {
  background-color: #e6f7ff;
}

.actions-list {
  flex-grow: 1;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
}

thead th {
  background-color: #f0f0f0;
}

.submit-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}
</style>
